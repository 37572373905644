export default {
    LABOR_RATE: 75,
    INSULATION_TYPE: [
        [1, 'Duct Insulation'],
        [2, 'Rigid Inuslation'],
        [3, 'Foil Face Insulation'],
        [4, 'K-Flex Duct Liner']
    ],
    INSULATION_SIZE: [
        [0, 'No Insulation'],
        [1, '1" Insulation'],
        [2, '2" Insulation'],
        [3, '3" Insulation']
    ],
    $METAL_TYPE: [
        [1, 'Galvanized'],
        [2, 'Aluminum'],
        [3, 'Galvalume'],
        [4, 'Stainless Steel']
    ],
    FLEX_DUCTING: [
        [0, 'No Flex Ducting'],
        [1, 'Flex Ducting']
    ],
    FLOOR: [
        [0, 'Standard Floor'],
        [1, 'Solid Floor (Entire Curb)'],
        [2, 'Solid Floor, Return Only'],
        [3, 'Solid Floor, Supply Only'],
        [4, 'Solid Floor, Return and Supply'],
        [5, '24 Gauge Entire Floor'],
        [6, '24 Gauge Return Only'],
        [7, '24 Gauge Supply Only'],
        [8, 'Field Cut Floor Opening']
    ],
    TUNNEL: [
        [0, 'Standard'],
        [1, 'Rotated 90°'],
    ],
    INSULATION_FLOOR: [
        [0, 'No Floor Insulation'],
        [1, 'Insulated Floor']
    ],
    DIVIDER_WALL: [
        [0, 'No Divider Wall'],
        [1, 'Divider Wall']
    ],
    WOOD_NAILER: [
        [0, 'No Nailer'],
        [1, '1x4 Nailer'],
        [2, '2x2 Nailer'],
        [3, '2x4 Nailer'],
        [4, '2x6 Nailer'],
        [5, '2x8 Nailer'],
        [6, '1x4 Treated Nailer'],
        [7, '2x2 Treated Nailer'],
        [8, '2x4 Treated Nailer'],
        [9, '2x6 Treated Nailer'],
        [10, '2x8 Treated Nailer'],
    ],
    OPTIONS: [
        [10, '18 Ga Doublewall Construction (Includes Walls and Floor, 2" Duct Board Insulation)'],
        [42, '2in Spring Deflection'],
        [12, 'Acoustical Package (Includes 2" Duct Board Insulation and 1" Gypsum Board on Floor)'],
        [3, 'Divider Wall'],
        [44, 'Duct support rails included'],
        [45, 'Flex Duct provided by CDI'],
        [59, 'Flex Duct provided by others'],
        [60, 'Field cut side discharge opening by others'],
        [14, 'Horizontal Return'],
        [13, 'Horizontal Supply'],
        [28, 'Insulated Condenser Panel'],
        [20, 'Insulated Shell w/Top Braces'],
        [1, 'Pipe Chase'],
        [49, 'Pitched Floor'],
        [30, 'Removable Cover'],
        [38, 'Return Floor Opening'],
        [25, 'Seismic Calculations'],
        [24, 'Seismic Restraints'],
        [39, 'Slab/Pad Mounted'],
        [47, 'Solid Flr Return Only'],
        [48, 'Solid Flr Supply Only'],
        [41, 'Special Crating'],
        [26, 'Special Material'],
        [51, 'Stradapter Offset'],
        [33, 'Structural Calculations'],
        [37, 'Supply Floor Opening'],
        [27, 'Top Brace'],
        [50, 'Rotate 90 Degrees'],
        [15, 'Weld In Burglar Bar'],
        [32, 'Wind Load Calculations'],
        [29, 'Wood Nailer'],
        [52, 'Damper Tray'],
        [53, 'Acoustical'],
        [54, 'Adapter L-Clip'],
        [55, 'Canted'],
        [56, '24 Gauge Return Only'],
        [57, '24 Gauge Supply Only'],
        [58, 'Field Cut Floor Opening'],

    ],
    OPTIONS_LEGACY: [
        [31, 'Access Door(s)'],
    ],

    CABINET_SIZE: [
        [1, 'AA1'],
        [2,'AA2'],
        [3, 'A1'],
        [4, 'A2'],
        [5, 'A3'],
        [6, 'B1'],
        [7, 'B2'],
        [8, 'B3'],
        [9, 'C1'],
        [10, 'C2'],
        [11, 'C3'],
        [12, 'C4'],
        [13, 'C5'],
        [14, 'No Price']
    ],
    DAMPER_TRAY: [
        [0, 'No Damper Tray'],
        [1, 'Damper Tray']
    ],
    ACOUSTICAL: [
        [0, 'No Acoustical'],
        [1, 'Acoustical']
    ],
    ADAPTER_CLIP: [
        [0, 'No Adapter Clip'],
        [1, 'Adapter L-Clip']
    ],
    CANTED: [
        [0, 'Not Canted'],
        [1, 'Canted']
    ],
}