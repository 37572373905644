<template>
    <div>
        <h3>Estimator #{{ isTransactionType ? data.curb_estimate_id : curb.default_estimate_id }}</h3>
        <h4>Dimensions</h4>
        <div class="row">
            <div v-show="isAdapter" class="col-md-2 col-sm-4 col-xs-6 form-group">
                <label>Old X</label>
                <input :tabindex="1" type="text" class="form-control" v-model.number="data.oldx">
            </div>
            <div v-show="isAdapter" class="col-md-2 col-sm-4 col-xs-6 form-group">
                <label>Old Y</label>
                <input :tabindex="2" type="text" class="form-control" v-model.number="data.oldy"></div>
            <div class="col-md-2 col-sm-4 col-xs-6 form-group">
                <label>New X</label>
                <input :tabindex="3" type="text" class="form-control" v-model.number="data.newx">
            </div>
            <div class="col-md-2 col-sm-4 col-xs-6 form-group">
                <label>New Y</label>
                <input :tabindex="4" type="text" class="form-control" v-model.number="data.newy">
            </div>
            <div class="col-md-2 col-sm-4 col-xs-6 form-group">
                <label>Height</label>
                <input :tabindex="5" type="text" class="form-control" v-model.number="data.height"></div>
            <div class="col-md-2 col-sm-4 col-xs-6 form-group">
                <label>Top</label>
                <input :tabindex="6" type="text" class="form-control" v-model.number="data.top"></div>
            <div v-show="isAdapter" class="col-md-2 col-sm-4 col-xs-6 form-group">
                <label>Bottom</label>
                <input :tabindex="7" type="text" class="adapter_only form-control" v-model.number="data.bottom">
            </div>
            <div v-show="!isAdapter" class="col-md-2 col-sm-4 col-xs-6 form-group">
                <label>Base</label>
                <input :tabindex="8" type="text" class="form-control" v-model.number="data.base"></div>
            <div v-show="!isAdapter" class="col-md-2 col-sm-4 col-xs-6 form-group">
                <label>Lip</label>
                <input :tabindex="9" type="text" class="form-control" v-model.number="data.lip">
            </div>
        </div>

        <div v-show="needsShippingQuote" class="alert alert-danger alert">A Shipping Quote is required for an item of these dimensions</div>

        <h4>Metal Type</h4>
        <div class="row" >
            <div class="col-md-2 col-sm-4 col-xs-6 form-group">
                <select :tabindex="9" class="form-control" v-model="data.metal_type">
                    <option value="1">Galvanized</option>
                    <option value="2">Aluminum</option>
                    <option value="3">Galvalume</option>
                    <option value="4">Stainless Steel</option>
                </select>
            </div>
        </div>

        <h4>Shell</h4>
        <div class="row" >
            <div class="col-md-2 col-sm-4 col-xs-6 form-group">
                <label>Material GA</label>
                <input :tabindex="10" type="text" class="form-control" v-model="data.shell_ga">
            </div>
            <div class="col-md-2 col-sm-4 col-xs-6 form-group">
                <label>Material $/lb</label>
                <div class="fixed form-control">{{ costs[data.metal_type] }}</div>
            </div>

            <div class="col-md-2 col-sm-4 col-xs-6 form-group">
                <label>Material WT</label>
                <div class="mid form-control">{{ shellMaterialWeight }}</div>
            </div>
            <div class="col-md-2 col-sm-4 col-xs-6 form-group">
                <label>Shell Area</label>
                <div class="mid form-control">{{ shellTotalArea }}</div>
            </div>
            <div class="col-md-2 col-sm-4 col-xs-6 form-group">
                <label>Total WT</label>
                <div class="mid form-control">{{ shellTotalWeight }}</div>
            </div>
            <div class="col-md-2 col-sm-4 col-xs-6 form-group">
                <label>Material $</label>
                <div class="result form-control">${{ shellMaterialCost }}</div>
            </div>
        </div>

        <h4>Guts</h4>
        <div class="row">
            <div class="col-md-2 col-sm-4 col-xs-6 form-group">
                <label>Material $/lb</label>
                <div class="fixed form-control">{{ costs[data.metal_type] }}</div>
            </div>
            <div class="col-md-2 col-sm-4 col-xs-6 form-group">
                <label>Material GA</label>
                <input :tabindex="11" type="text" class="form-control" v-model="data.guts_ga">
            </div>
            <div class="col-md-2 col-sm-4 col-xs-6 form-group">
                <label>Material WT</label>
                <div class="mid form-control">{{ gutsMaterialWeight }}</div>
            </div>
            <div class="col-md-2 col-sm-4 col-xs-6 form-group">
                <label>Guts Area</label>
                <div class="mid form-control">{{ gutsTotalArea }}</div>
            </div>
            <div class="col-md-2 col-sm-4 col-xs-6 form-group">
                <label>Guts WT</label>
                <div class="mid form-control">{{ gutsTotalWeight }}</div>
            </div>
            <div class="col-md-2 col-sm-4 col-xs-6 form-group">
                <label>Guts MT $</label>
                <div class="result form-control">${{ gutsMaterialCost }}</div>
            </div>
        </div>

        <h4>Guts Pieces</h4>
        <div class="spacing_btm">
            <div class="row">
                <label class="col-xs-4">Length</label>
                <label class="col-xs-4">Height</label>
                <label class="col-xs-4">#PCS</label>
            </div>
            <div class="grid">
                <div v-if="data.guts">
                    <div v-for="(gut, id) in data.guts" :key="id" class="grid_row">
                        <input v-show="gut !== newestGutInput" :tabindex="12" class="grid_cell form-control" type="text" v-model.number="gut.length" @change="changeTab">
                        <input v-show="gut !== newestGutInput" :tabindex="12.1" class="grid_cell form-control" type="text" v-model.number="gut.height">
                        <input v-show="gut !== newestGutInput" :tabindex="12.2" class="grid_cell form-control" type="text" v-model.number="gut.pcs" >
                    </div>
                </div>
                <input :tabindex="12" id="gutStart" class="grid_cell form-control" type="text" v-model.number="newestGutInput.length" >
                <input :tabindex="12.1" class="grid_cell form-control" type="text" v-model.number="newestGutInput.height">
                <input :tabindex="12.2" class="grid_cell form-control" type="text" v-model.number="newestGutInput.pcs" @keydown.tab.prevent="addGridRow('guts', 'gutStart')" >
            </div>
            <div class="clearfix"></div>
        </div>

        <h4>Floor</h4>
        <div class="row">
            <div class="col-md-2 col-sm-4 col-xs-6 form-group">
                <label>Material $/lb</label>
                <div class="fixed form-control">{{ costs[data.metal_type] }}</div>
            </div>
            <div class="col-md-2 col-sm-4 col-xs-6 form-group">
                <label>Material GA</label>
                <input id="gutsNext" :tabindex="14" type="text" class="form-control" v-model="data.floor_ga">
            </div>
            <div class="col-md-2 col-sm-4 col-xs-6 form-group">
                <label>Material WT</label>
                <div class="mid form-control">{{ floorMaterialWeight }}</div>
            </div>
            <div class="col-md-2 col-sm-4 col-xs-6 form-group">
                <label>Floor Area</label>
                <div class="mid form-control">{{ floorTotalArea }}</div>
            </div>
            <div class="col-md-2 col-sm-4 col-xs-6 form-group">
                <label>Floor WT</label>
                <div class="mid form-control">{{ floorTotalWeight }}</div>
            </div>
            <div class="col-md-2 col-sm-4 col-xs-6 form-group">
                <label>Floor Mat $</label>
                <div class="result form-control">${{ floorMaterialCost }}</div>
            </div>
        </div>
        <div>
            <label><input :tabindex="14" type="checkbox" v-model="data.floor_solid" :true-value="1" :false-value="0"> Solid Floor Entire Curb</label>
        </div>
        <h4>Floor Pieces</h4>
        <div class="spacing_btm">
            <div class="row">
                <label class="col-xs-4">Length</label>
                <label class="col-xs-4">Height</label>
                <label class="col-xs-4">#PCS</label>
            </div>
            <div class="grid">
                <div v-if="data.floor">
                    <div v-for="(floorPiece, id) in data.floor" :key="id" class="grid_row">
                            <input v-show="floorPiece !== newestFloorInput" :tabindex="15" class="grid_cell form-control" type="text" v-model.number="floorPiece.length" @change="changeTab">
                            <input v-show="floorPiece !== newestFloorInput" :tabindex="15.1" class="grid_cell form-control" type="text" v-model.number="floorPiece.height">
                            <input v-show="floorPiece !== newestFloorInput" :tabindex="15.2" class="grid_cell form-control" type="text" v-model.number="floorPiece.pcs" >
                    </div>
                </div>
                <input :tabindex="15" id="floorStart" class="grid_cell form-control" type="text" v-model.number="newestFloorInput.length" >
                <input :tabindex="15.1" class="grid_cell form-control" type="text" v-model.number="newestFloorInput.height">
                <input :tabindex="15.2" class="grid_cell form-control" type="text" v-model.number="newestFloorInput.pcs" @keydown.tab.prevent="addGridRow('floor', 'floorStart')" >
            </div>
            <div class="clearfix"></div>
        </div>

        <h4>Insulation</h4>
        <div class="row">
            <div class="col-md-2 col-sm-4 col-xs-6 form-group">
                <label>Size (in)</label>
                <select id="floorNext" :tabindex="16" class="form-control" v-model="data.insulation_size">
                    <option value="0">NO</option>
                    <option value="1">1"</option>
                    <option value="2">2"</option>
                    <option value="3">3"</option>
                </select>
            </div>
            <div class="col-md-2 col-sm-4 col-xs-6 form-group">
                <label>Type</label>
                <select :tabindex="17" class="form-control" v-model="data.insulation_type">
                    <option value="1">Duct</option>
                    <option value="2">Rigid</option>
                    <option value="3">Foil</option>
                    <option value="4">K-Flex</option>
                </select>
            </div>
            <div class="col-md-2 col-sm-4 col-xs-6 form-group">
                <label>Mat. $/ft&sup2;</label>
                <div class="fixed form-control">{{ insulationCosts[data.insulation_type][data.insulation_size] }}</div>
            </div>
            <div class="col-md-2 col-sm-4 col-xs-6 form-group">
                <label>Area</label>
                <div class="mid form-control">{{ insulationArea }}</div>
            </div>
            <div class="col-md-2 col-sm-4 col-xs-6 form_grid">
                <label><input :tabindex="18" type=checkbox v-model="data.insulation_floor" :true-value="1" :false-value="0"> Floor</label>
            </div>
            <div class="col-md-2 col-sm-4 col-xs-6 form-group">
                <label>Floor Area</label>
                <div class="mid form-control">{{ insulationFloorArea }}</div>
            </div>
            <div class="col-md-2 col-sm-4 col-xs-6 form-group">
                <label>Insul. Mat $</label>
                <div class="result form-control">${{ insulationMaterialCost + insulationFloorMaterialCost }}</div>
            </div>
        </div>

        <h4>Angle Iron (per 10ft pieces):</h4>
        <div class="row">
            <div class="col-md-2 col-sm-4 col-xs-6 form-group">
                <label>1/4 ai</label>
                <input :tabindex="19" type="text" class="form-control" v-model.number="data.angle_iron_pcs1">
            </div>
            <div class="col-md-2 col-sm-4 col-xs-6 form-group">
                <label>Mat. $/ft</label>
                <div class="fixed form-control">{{ costs[5] }}</div>
            </div>
            <div class="col-md-2 col-sm-4 col-xs-6 form-group">
                <label>2x2x1/4</label>
                <input :tabindex="20" type="text" class="form-control" v-model.number="data.angle_iron_pcs2">
            </div>
            <div class="col-md-2 col-sm-4 col-xs-6 form-group">
                <label>Mat. $/ft</label>
                <div class="fixed form-control">{{ costs[6] }}</div>
            </div>
            <div class="col-md-2 col-sm-4 col-xs-6 form-group">
                <label>Tube Steel</label>
                <input :tabindex="21" type="text" class="form-control" v-model.number="data.tubesteel_pcs">
            </div>
            <div class="col-md-2 col-sm-4 col-xs-6 form-group">
                <label>Mat. $/ft</label>
                <div class="fixed form-control">{{ costs[7] }}</div>
            </div>
            <div class="col-md-2 col-sm-4 col-xs-6 form-group">
                <label>Material $</label>
                <div class="result form-control">${{ angleIronMaterialCost }}</div>
            </div>
        </div>

        <h4>Gasket</h4>
        <div class="row">
            <div class="col-md-2 col-sm-4 col-xs-6 form-group">
                <label>Rolls</label>
                <div class="mid form-control">{{ gasketRolls }}</div>
            </div>
            <div class="col-md-2 col-sm-4 col-xs-6 form-group">
                <label>$/ft</label>
                <div class="fixed form-control">{{ costs[10] }}</div>
            </div>
            <div class="col-md-2 col-sm-4 col-xs-6 form-group">
                <label>Material $</label>
                <div class="result form-control">${{ gasketMaterialCost }}</div>
            </div>
        </div>

        <h4>24ga</h4>
        <div class="row">
            <div class="col-md-2 col-sm-4 col-xs-6 form-group">
                <label>Area</label>
                <div class="mid form-control">{{ ga24TotalArea }}</div>
            </div>
            <div class="col-md-2 col-sm-4 col-xs-6 form-group">
                <label>$/ft</label>
                <div class="fixed form-control">{{ costs[1] }}</div>
            </div>
            <div class="col-md-2 col-sm-4 col-xs-6 form-group">
                <label>Material $</label>
                <div class="result form-control">${{ ga24MaterialCost }}</div>
            </div>
        </div>
        <div class="row">
            <label class="col-xs-4">Length</label>
            <label class="col-xs-4">Height</label>
            <label class="col-xs-4">#PCS</label>
        </div>

        <div class="grid">
            <div v-if="data.ga">
                <div v-for="(gaPiece, id) in data.ga" :key="id" class="grid_row">
                    <input v-show="gaPiece !== newestGaInput" :tabindex="22" class="grid_cell form-control" type="text" v-model.number="gaPiece.length" @change="changeTab">
                    <input v-show="gaPiece !== newestGaInput" :tabindex="22.1" class="grid_cell form-control" type="text" v-model.number="gaPiece.height">
                    <input v-show="gaPiece !== newestGaInput" :tabindex="22.2" class="grid_cell form-control" type="text" v-model.number="gaPiece.pcs" >
                </div>
            </div>
            <input :tabindex="22" id="gaStart" class="grid_cell form-control" type="text" v-model.number="newestGaInput.length" >
            <input :tabindex="22.1" class="grid_cell form-control" type="text" v-model.number="newestGaInput.height">
            <input :tabindex="22.2" class="grid_cell form-control" type="text" v-model.number="newestGaInput.pcs" @keydown.tab.prevent="addGridRow('ga', 'gaStart')" >
        </div>
        <div class="clearfix"></div>

        <h4>Lumber</h4>
        <div class="row">
            <div class="col-md-2 col-sm-4 col-xs-6 form-group">
                <label>#2x4</label>
                <input id="gaNext" :tabindex="25" type="text" class="form-control" v-model.number="data.lumber_pcs">
            </div>
            <div class="col-md-2 col-sm-4 col-xs-6 form-group">
                <label>$/pc</label>
                <div class="fixed form-control">{{ costs[9] }}</div>
            </div>
            <div class="col-md-2 col-sm-4 col-xs-6 form-group">
                <label>2x4 $</label>
                <div class="result form-control">${{ lumber2x4MaterialCost }}</div>
            </div>
            <div class="col-md-2 col-sm-4 col-xs-6 form-group">
                <label>ft of 1x4's</label>
                <div class="mid form-control">${{ lumberFT }}</div>
            </div>
            <div class="col-md-2 col-sm-4 col-xs-6 form-group">
                <label>$/ft</label>
                <div class="fixed form-control">{{ costs[8] }}</div>
            </div>
            <div class="col-md-2 col-sm-4 col-xs-6 form-group">
                <label>1x4 $</label>
                <div class="result form-control">${{ lumber1x4MaterialCost }}</div>
            </div>
            <div class="col-md-2 col-sm-4 col-xs-6 form-group">
                <label>Material $</label>
                <div class="result form-control">${{ lumberMaterialCost }}</div>
            </div>
        </div>

        <h4 v-show="!isAdapter">DoubleWall</h4>
        <div v-show="!isAdapter"  class="row">
            <div class="col-md-2 col-sm-4 col-xs-6 form-group">
                <label>Walls</label>
                <select :tabindex="26" class="form-control" v-model="data.dw_walls">
                    <option value="0">NO</option>
                    <option value="1">YES</option>
                </select>
            </div>
            <div class="col-md-2 col-sm-4 col-xs-6 form-group">
                <label>Walls Mat $</label>
                <div class="result form-control">${{ dwWallsMaterialCost }}</div>
            </div>
            <div class="col-md-2 col-sm-4 col-xs-6 form-group">
                <label>Floor</label>
                <select :tabindex="27" class="form-control" v-model="data.dw_floors">
                    <option value="0">NO</option>
                    <option value="1">YES</option>
                </select>
            </div>
            <div class="col-md-2 col-sm-4 col-xs-6 form-group">
                <label>Floor Mat $</label>
                <div class="result form-control">${{ dwFloorsMaterialCost }}</div>
            </div>
            <div class="col-md-2 col-sm-4 col-xs-6 form-group">
                <label>Perf lining</label>
                <select :tabindex="28" class="form-control" v-model="data.perf">
                    <option value="0">NO</option>
                    <option value="1">YES</option>
                </select>
            </div>
            <div class="col-md-2 col-sm-4 col-xs-6 form-group">
                <label>$/ft&sup2;</label>
                <div class="fixed form-control">{{ costs[11] }}</div>
            </div>
            <div class="col-md-2 col-sm-4 col-xs-6 form-group">
                <label>Perf Mat $</label>
                <div class="result form-control">${{ perfMaterialCost }}</div>
            </div>
            <div class="col-md-2 col-sm-4 col-xs-6 form-group">
                <label>Acous. PKG</label>
                <select :tabindex="29" class="form-control" v-model="data.dw_accou">
                    <option value="0">NO</option>
                    <option value="1">YES</option>
                </select>
            </div>
            <div class="col-md-2 col-sm-4 col-xs-6 form-group">
                <label>Area</label>
                <div class="mid form-control">{{ dwAcousticalArea }}</div>
            </div>
            <div class="col-md-2 col-sm-4 col-xs-6 form-group">
                <label>Insu. WT</label>
                <div class="mid form-control">{{ dwInsulationWeight }}</div>
            </div>
            <div class="col-md-2 col-sm-4 col-xs-6 form-group">
                <label>$/ft&sup2;</label>
                <div class="fixed form-control">{{ costs[12] }}</div>
            </div>
            <div class="col-md-2 col-sm-4 col-xs-6 form-group">
                <label>St. Rock WT</label>
                <div class="mid form-control">{{ dwSheetRockWeight }}</div>
            </div>
            <div class="col-md-2 col-sm-4 col-xs-6 form-group">
                <label>$/ft&sup2;</label>
                <div class="fixed form-control">{{ costs[13] }}</div>
            </div>
            <div class="col-md-2 col-sm-4 col-xs-6 form-group">
                <label>Acc. Mat $</label>
                <div class="result form-control">${{ dwAcousticalMaterialCost }}</div>
            </div>
        </div>

        <h4>Nailer</h4>
        <div class="row">
            <div class="col-md-2 col-sm-4 col-xs-6 form-group">
                <label>Wood Nailer</label>
                <select :tabindex="30" class="form-control" v-model="data.wood_nailer">
                    <option value="-1">Unset</option>
                    <option v-for="[id, woodNailer] in cache.curbCache.WOOD_NAILER" v-bind:key="id" :value="id">{{woodNailer}}</option>
                </select>
            </div>
        </div>

        <h4>Labor</h4>
        <div class="row">
            <div v-show="isAdapter" class="col-md-2 col-sm-4 col-xs-6 form-group">
                <label>Plasma</label>
                <input :tabindex="31" type="text" class="form-control" v-model.number="data.labor_plasma">
            </div>
            <div v-show="isAdapter" class="col-md-2 col-sm-4 col-xs-6 form-group">
                <label>Grind</label>
                <input :tabindex="32" type="text" class="form-control" v-model.number="data.labor_grind">
            </div>
            <div v-show="!isAdapter" class="col-md-2 col-sm-4 col-xs-6 form-group">
                <label>Shear</label>
                <input :tabindex="33" type="text" class="form-control" v-model.number="data.labor_shear">
            </div>
            <div class="col-md-2 col-sm-4 col-xs-6 form-group">
                <label>Form</label>
                <input :tabindex="34" type="text" class="form-control" v-model.number="data.labor_form">
            </div>
            <div v-show="isAdapter" class="col-md-2 col-sm-4 col-xs-6 form-group">
                <label>Clinch</label>
                <input :tabindex="35" type="text" class="form-control" v-model.number="data.labor_clinch"></div>
            <div class="col-md-2 col-sm-4 col-xs-6 form-group">
                <label>Shell</label>
                <input :tabindex="36" type="text" class="form-control" v-model.number="data.labor_shell">
            </div>
            <div class="col-md-2 col-sm-4 col-xs-6 form-group">
                <label>Gut</label>
                <input :tabindex="37" type="text" class="form-control" v-model.number="data.labor_gut">
            </div>
            <div v-show="!isAdapter" class="col-md-2 col-sm-4 col-xs-6 form-group">
                <label>Nailer</label>
                <input :tabindex="38" type="text" class="form-control" v-model.number="data.labor_nailer">
            </div>
            <div v-show="isAdapter" class="col-md-2 col-sm-4 col-xs-6 form-group">
                <label>Insulate</label>
                <input :tabindex="39" type="text" class="form-control" v-model.number="data.labor_insulate">
            </div>
            <div class="col-md-2 col-sm-4 col-xs-6 form-group">
                <label>Crate</label>
                <input :tabindex="40" type="text" class="form-control" v-model.number="data.labor_crate">
            </div>
            <div class="col-md-2 col-sm-4 col-xs-6 form-group">
                <label>Total(min)</label>
                <div class='mid form-control'>{{ laborMins }}</div>
            </div>
            <div class="col-md-2 col-sm-4 col-xs-6 form-group">
                <label>Build Hours</label>
                <div class="mid form-control">{{ laborHours }}</div>
            </div>
            <div class="col-md-2 col-sm-4 col-xs-6 form-group">
                <label>Labor Rate</label>
                <div class="fixed form-control">{{ cache.curbCache.LABOR_RATE }}</div>
            </div>
            <div class="col-md-2 col-sm-4 col-xs-6 form-group">
                <label>Labor $</label>
                <div class="result form-control">${{ laborCost }}</div>
            </div>
        </div>

        <h4>Freight</h4>
        <div class="row">
            <div class="col-md-2 col-sm-4 col-xs-6 form-group">
                <label>Location</label>
                <select :tabindex="41" class="form-control" v-model="data.cdi_location_id" @change="updateFreight">
                    <option v-for="[cdiLocationID, cdiLocation] in cache.cdiLocationCache.CDI_LOCATIONS" v-bind:key="cdiLocationID" :value="cdiLocationID">{{ cdiLocation }}</option>
                </select>
            </div>
            <div class="col-sm-4 col-xs-6 form-group">
                <label>Destination [City, State] or [Zipcode]</label>
                <input :tabindex="42" type="text" class="form-control" v-model="data.ship_destination" @change="updateFreight">
            </div>
            <div class="col-md-2 col-sm-4 col-xs-6 form-group">
                <label>Truck</label>
                <select :tabindex="43" class="form-control" v-model="data.truck" @change="updateFreight">
                    <option value="0">LTL</option>
                    <option value="1">Flat Half</option>
                    <option value="2">Flat Full</option>
                    <option value="4">Truckload</option>
                    <option value="5">Local</option>
                </select>
            </div>
            <div class="col-md-2 col-sm-4 col-xs-6 form-group">
                <label>$ per Mile</label>
                <div class="mid form-control">{{ freightPrice }}</div>
            </div>
            <div class="col-md-2 col-sm-4 col-xs-6 form-group">
                <label>Miles</label>
                <div class="mid form-control">{{ freightDistance }}</div>
            </div>
            <div class="col-md-2 col-sm-4 col-xs-6 form-group">
                <label>Freight</label>
                <input :tabindex="44" type="text" class="oc form-control" v-model.number="data.freight_cost">
            </div>
        </div>

        <h4>Extra(s)</h4>
        <div class="row">
            <div class="col-md-2 col-sm-4 col-xs-6 form-group">
                <label>Extra(s)</label>
                <input :tabindex="45" type="text" class="form-control" v-model.number="data.extra_cost">
            </div>
            <div class="col-md-10 col-sm-8 col-xs-6 form-group">
                <label>Description</label>
                <textarea :tabindex="46" class="form-control" v-model="data.extra_description"></textarea>
            </div>
        </div>

        <div class="row">
            <div v-for="[curbOptionID, option] in cache.curbCache.OPTIONS" :key="curbOptionID" class="col-lg-3 col-md-4 col-sm-6 col-xs-6">
                <label><input type="checkbox" :tabindex="47" class="extra_option" v-model="curbOptions" :value="curbOptionID"> {{ option }}</label></div>
        </div>

      <h4>Legacy Options</h4>
      <div class="row">
        <div v-for="[curbOptionID, option] in cache.curbCache.OPTIONS_LEGACY" :key="curbOptionID" class="col-lg-3 col-md-4 col-sm-6 col-xs-6">
          <label><input type="checkbox" :tabindex="47" class="extra_option" v-model="curbOptions" :value="curbOptionID"> {{ option }}</label></div>
      </div>
      <div>
        <label><input :tabindex="14" type="checkbox" v-model="data.filter_rack" :true-value="1" :false-value="0"> Filter Rack</label>
      </div>


      <h4>Cost</h4>
        <div class="row">
            <div class="col-md-2 col-sm-4 col-xs-6 form-group">
                <label>Total WT</label>
                <div class="mid form-control">{{ totalWeight }}</div>
            </div>
            <div class="col-md-2 col-sm-4 col-xs-6 form-group">
                <label>Labor</label>
                <div class="mid form-control">${{ laborCost }}</div>
            </div>
            <div class="col-md-2 col-sm-4 col-xs-6 form-group">
                <label>Labor %</label>
                <div class="result form-control">{{ laborPercent }}%</div>
            </div>
            <div class="col-md-2 col-sm-4 col-xs-6 form-group">
                <label>Materials</label>
                <div class="mid form-control">${{ materialsTotal }}</div>
            </div>
            <div class="col-md-2 col-sm-4 col-xs-6 form-group">
                <label>Materials %</label>
                <div class="result form-control">{{ materialsPercent }}%</div>
            </div>
            <div class="col-md-2 col-sm-4 col-xs-6 form-group">
                <label>Freight</label>
                <div class="result form-control">${{ data.freight_cost }}</div>
            </div>
            <div class="col-md-2 col-sm-4 col-xs-6 form-group">
                <label>Freight %</label>
                <div class="result form-control">{{ freightPercent }}%</div>
            </div>
            <div class="col-md-2 col-sm-4 col-xs-6 form-group">
                <label>Extra</label>
                <div class="mid form-control">${{ data.extra_cost }}</div>
            </div>
            <div class="col-md-2 col-sm-4 col-xs-6 form-group">
                <label>Extra %</label>
                <div class="result form-control">{{ extraPercent }}%</div>
            </div>
            <div class="col-md-2 col-sm-4 col-xs-6 form-group">
                <label>COGS</label>
                <div class="mid form-control">${{ cogsTotal }}</div>
            </div>
            <div class="col-md-2 col-sm-4 col-xs-6 form-group">
                <label>COGS %</label>
                <div class="result form-control">{{ cogsPercent }}%</div>
            </div>
            <div class="col-md-2 col-sm-4 col-xs-6 form-group">
                <label>GP</label>
                <input :tabindex="48" type="text" class="form-control" v-model.number="data.gp">
            </div>
        </div>

        <h3>Sell ${{ sellNum }}</h3>
        <h5>If price is shown as $NaN, it's likely shell area cannot calculate because dimensions are not provided. Add either Old X/Old Y or New X/New Y to calculate price.</h5>

        <h4>Notes</h4>
        <textarea :tabindex="49" class="form-control spacing_btm" v-model="data.curb_estimate_notes"></textarea>

        <div class="btn-group" v-if="isTransactionType">
            <input v-if="!creditCardLock" :tabindex="50" type="button" value="Save and Insert Price" class="btn btn-default" @click="save">
            <input :tabindex="51" type="button" value="Save as Item Default" class="btn btn-default" @click="saveDefault" :disabled="!curb.default_estimate_id == '0'">
            <input :tabindex="52" type="button" value="Cancel" class="btn btn-default" @click="cancel">
        </div>
        <div class="btn-group" v-else>
            <input :tabindex="53" type="button" value="Save Estimator" class="btn btn-default" @click="save">
            <input :tabindex="54" type="button" value="Cancel" class="btn btn-default" @click="cancel">
        </div>
    </div>
</template>

<script>
import { store } from '@/store/BusinessObject.store.js';
import curbCache from  '@/cache/curb.cache.js';
import cdiLocationCache from  '@/cache/cdiLocation.cache.js';
export default {
    name: "Estimator",
    props: ['businessObject', 'curb', 'creditCardLock'],
    data() {
        return{
            state: store.state,
            data: {
                'metal_type': 1,
                'shell_ga': 18,
                'guts': [],
                'floor': [],
                'ga': [],
                'insulation_type': 1,
                'insulation_size': 1,
            },
            gutLength: '',
            gutHeight: '',
            gutPcs: '',
            floorLength: '',
            floorHeight: '',
            floorPcs: '',
            costs: {},
            curbOptions: [],
            metalWeight: { // metal type 1 is galvanized, metal type 2 is aluminum, metal type 3 is galvalume, metal type 4 is stainless steel
                '1': {'12': 4.506, '14': 3.26, '16': 2.635, '18': 2.139, '24': 1.156},
                '2': {'14': 1.397, '16': 1.16, '18': 0.88, '0.100': 1.397, '0.080': 1.16, '0.063': 0.88},
                '3': {'12': 4.531, '14': 3.281, '16': 2.656, '18': 2.156},
                '4': {'12': 4.4247, '14': 3.154, '16': 2.499, '18': 2.016}
            },
            cache: {
                curbCache,
                cdiLocationCache,
            },
            freightDistance: 0,
            freightPrice: 0,
        }
    },
    methods: {
        setFloats: function(obj) {
            let numberFields = ['oldx', 'oldy', 'newx', 'newy', 'height', 'top', 'bottom', 'base', 'lip', 'angle_iron_pcs1',
                'angle_iron_pcs2', 'tubesteel_pcs', 'lumber_pcs', 'labor_plasma', 'labor_grind', 'labor_form', 'labor_clinch',
                'labor_shell', 'labor_gut', 'labor_insulate', 'labor_crate', 'labor_shear', 'labor_nailer', 'freight_cost',
                'materials_cost', 'extra_cost', 'total_wt', 'gp'
            ];
            for (let i = 0; i < numberFields.length; i++) {
                obj[numberFields[i]] = +(obj[numberFields[i]]); // convert to float
            }
            for (let i = 0; i < obj.guts.length; i++) {
                obj.guts[i].length = +obj.guts[i].length;
                obj.guts[i].height = +obj.guts[i].height;
                obj.guts[i].pcs = +obj.guts[i].pcs;
            }
            return obj;
        },
        updateFreight: function() {
            let zips = ['0', '55330', '98424', '28269', '75063', '85353']; // cdi plant zips
            if (this.data.cdi_location_id && this.data.ship_destination && this.data.truck){
                let params = {
                    'params[origin]': zips[this.data.cdi_location_id],
                    'params[destination]': this.data.ship_destination,
                    'params[truck]': this.data.truck
                };
                store.apiGeneric('address/distance_w_price', params)
                    .then(results => {
                        this.freightDistance = results.distance;
                        this.freightPrice = results.freight_price;
                        this.freightGroupPrice = results.freight_group_price;
                        if (this.freightGroupPrice) {
                            this.data.freight_cost = (this.freightGroupPrice * 1);
                        } else {
                            let cost = (this.freightDistance * this.freightPrice);
                            this.data.freight_cost = (cost < 600 && cost != 0 ? 600 : cost); // (business logic ;)
                        }
                    });
            }
        },
        round2: function(num) {
            return Math.round(num * 100) / 100;
        },
        round3: function(num) {
            return Math.round(num * 1000) / 1000;
        },
        cancel: function() {
            this.$emit('changeView', {view: 'details'});
        },
        save: function() {
            let params = {};
            this.data.options = {};
            for (let i = 0; i < this.curbOptions.length; i++) {
                this.data.options[this.curbOptions[i]] = this.curbOptions[i];
            }
            for (const field in this.data) {
                params[`params[${field}]`] = this.data[field];
            }
            store.apiGeneric(`${this.businessObject}/save_curb_estimate/${this.id}`, params)
                .then((results) => this.afterSave(results.curb_estimate_id));
        },
        saveDefault: function() {
            let params = {};
            this.data.options = {};
            for (let i = 0; i < this.curbOptions.length; i++) {
                this.data.options[this.curbOptions[i]] = this.curbOptions[i];
            }
            for (const field in this.data) {
                params[`params[${field}]`] = this.data[field];
            }
            params['params[curb_id]'] = this.curb.curb_id;
            store.apiGeneric(`${this.businessObject}/save_curb_estimate_default/${this.id}`, params)
                .then((results) => this.afterSave(results.curb_estimate_id));
        },
        afterSave: function(curbEstimateID) {
            if (this.businessObject == 'quote_curb') {
                this.curb.price_override = this.sellNum;
                this.curb.curb_estimate_id = curbEstimateID;
                this.$emit('changeView',({ ...this.curb, ...{view: 'editItem'} }));
            }else {
                this.$emit('changeView', {view: 'details'});
            }
        },
        addGridRow: function(gridName, nextTab = null) {
            let addRow = true;
            for (let i = 0; i < this.data[gridName].length; i++){
                if (this.data[gridName][i]['length'] === '' || this.data[gridName][i]['height'] === '' || this.data[gridName][i]['pcs'] === ''){
                    addRow = false;
                }
            }
            if (addRow) {
                this.data[gridName].push({length: '', height: '', pcs: ''})
                if (nextTab) {
                    document.getElementById(nextTab).focus();
                }
            }else {
                if(gridName == "ga") {
                    document.getElementById('gaNext').focus();
                }else if(gridName == "floor"){
                    document.getElementById('floorNext').focus();
                }else if(gridName == "guts"){
                    document.getElementById('gutsNext').focus();
                }
            }
        },
        changeTab: function (){
            document.getElementById("add").tabIndex = 13;
        },
    },
    watch: {
        id: {
            immediate: true,
            handler() {
                if (this.id && this.state.currentView == 'estimator') {
                    store.apiGeneric(`${this.businessObject}/get_estimate/${this.id}`, {})
                        .then(results => {
                            this.data = this.setFloats(results); // convert strings to floats
                            this.curbOptions = Object.keys(results.options); // set up curb options
                            this.addGridRow('guts');
                            this.addGridRow('floor');
                            this.addGridRow('ga');
                        });
                    store.apiGeneric('curb_estimator/material_cost_json', {})
                        .then(results => this.costs = results.data);
                }
            }
        }
    },
    computed: {
        newestGutInput: function() {
            if(this.data.guts.length > 0){
                return this.data.guts[this.data.guts.length - 1]
            }else{
                return this.data.guts.length == 0;
            }
        },
        newestFloorInput: function() {
            if(this.data.floor.length > 0){
                return this.data.floor[this.data.floor.length - 1]
            }else{
                return this.data.floor.length == 0;
            }
        },
        newestGaInput: function() {
            if(this.data.ga.length > 0){
                return this.data.ga[this.data.ga.length - 1]
            }else{
                return this.data.ga.length == 0;
            }
        },
        isTransactionType: function () {
            return ['quote_curb', 'tracker_curb'].includes(this.businessObject);
        },
        id: function() {
            let id = 0;
            if (this.businessObject == 'quote_curb') {
                id = this.curb.quote_curb_id;
            }else if (this.businessObject == 'tracker_curb') {
                id = this.curb.tracker_invoice_id;
            }else if (this.businessObject == 'curb') {
                id = this.curb.curb_id;
            }
            return id;
        },
        isAdapter: function() {
            if (this.curb.curb_type_id == 1 || this.curb.curb_type_id == 19 || this.curb.curb_type_id == 21 || this.curb.curb_type_id == 16){
                return true;
            }else {
                return false;
            }
        },
        needsShippingQuote: function() {
            let maxX = this.data.newx + this.data.base;
            let maxY = this.data.newy + this.data.base;
            let max_X = this.data.oldx + this.data.base;
            let max_Y = this.data.oldy + this.data.base;
            let count = 0;
            let count_old = 0;
            if (maxX > 95) {
                count++;
            }
            if (maxY > 95) {
                count++;
            }
            if (max_X > 95) {
                count_old++;
            }
            if (max_Y > 95) {
                count_old++;
            }
            if (this.data.height > 95) {
                count++;
            }
            if (count >= 2 || count_old >= 2 || maxX > 144 || maxY > 144 || max_X > 144 || max_Y > 144 || this.data.height > 144) {
                return true;
            }else {
                return false;
            }
        },
        scrap: function() {
            return this.isAdapter ? 0.25 : 0.10
        },
        insulationCosts: function() {
            return [
                [0, 0, 0], // None
                [ // Duct
                    0,
                    this.costs[14], // 1"
                    this.costs[15], // 2"
                    this.costs[16]  // 3"
                ],
                [ // Rigid
                    0,
                    this.costs[17], // 1"
                    this.costs[18],  // 2"
                    this.costs[23] // 3"
                ],
                [  // Foil
                    0,
                    this.costs[19], // 1"
                    this.costs[20],  // 2"
                    this.costs[24], // 3"
                ],
                [ // K-Flex
                    0,
                    this.costs[21], // 1'
                    this.costs[22],  // 2"
                    this.costs[25] // 3"
                ]
            ];
        },
        // Shell Calcs
        shellMaterialWeight: function() {
            return this.metalWeight[this.data.metal_type][this.data.shell_ga];
        },
        shellTotalArea: function() {
            if (this.isAdapter) {
                let aDim = (this.data.oldx - this.data.newx) / 2;
                let aAngle = Math.atan(aDim / (this.data.height - this.data.top - this.data.bottom)) * 180 / Math.PI;
                let aDimFlat = this.data.top + this.data.bottom + (aDim / Math.sin(aAngle * Math.PI / 180)) + 1.75;
                let shellSide1 = (this.data.oldy >= this.data.newy) ? (this.data.oldy * aDimFlat / 144) : (this.data.newy * aDimFlat / 144);
                let bDimFlat = this.data.height + 1.75;
                let shellSide2 = (this.data.oldx >= this.data.newx) ? this.data.oldx * bDimFlat / 144 : this.data.newx * bDimFlat / 144;
                let cDim = aDim;
                let cAngle = Math.atan(cDim / (this.data.height - this.data.top - this.data.bottom)) * 180 / Math.PI;
                let cDimFlat = this.data.top + this.data.bottom + (cDim / Math.sin(cAngle * Math.PI / 180)) + 1.75;
                let shellSide3 = (this.data.oldy >= this.data.newy) ? this.data.oldy * cDimFlat / 144 : this.data.newy * cDimFlat / 144;
                let dDim = this.data.oldy - this.data.newy;
                let dAngle = Math.atan(dDim / (this.data.height - this.data.top - this.data.bottom)) * 180 / Math.PI;
                let dDimFlat = this.data.top + this.data.bottom + (dDim / Math.sin(dAngle * Math.PI / 180)) + 1.75;
                let shellSide4 = (this.data.oldx >= this.data.newx) ? this.data.oldx * dDimFlat / 144 : this.data.newx * dDimFlat / 144;
                let braceX = this.data.oldx - 6.125;
                let shellBrace1 = 2 * (2 * 3 * braceX) / 144;
                let braceY = this.data.oldy - 0.125;
                let shellBrace2 = 2 * (2 * 3 * braceY) / 144;
                return this.round3((shellSide1 + shellSide2 + shellSide3 + shellSide4 + shellBrace1 + shellBrace2) * (1 + this.scrap));
            }else{
                let longWall = this.data.newx - 2 * this.data.top;
                let shortWall = this.data.newy - 2 * this.data.top;
                let sideA = (longWall * (this.data.height + this.data.top + this.data.base + this.data.lip) / 144) * 2;
                let sideB = (shortWall * (this.data.height + this.data.top + this.data.base + this.data.lip) / 144) * 2;
                return this.round3((sideA + sideB) * (1 + this.scrap));
            }
        },
        shellTotalWeight: function() {
            return this.round3(this.shellMaterialWeight * this.shellTotalArea);
        },
        shellMaterialCost: function() {
            return this.round2(this.costs[this.data.metal_type] * this.shellTotalWeight);
        },
        // Guts calcs
        gutsMaterialWeight: function() {
            return this.metalWeight[this.data.metal_type][this.data.guts_ga];
        },
        gutsTotalArea: function() {
            let area = 0;
            for (let i = 0; i < this.data.guts.length; i++) {
                area += (this.data.guts[i]['length'] * this.data.guts[i].height * this.data.guts[i].pcs) / 144;
            }
            return this.round3(area * 1.25);
        },
        gutsTotalWeight: function() {
            return this.round3(this.gutsMaterialWeight * this.gutsTotalArea);
        },
        gutsMaterialCost: function() {
            return this.round2(this.costs[this.data.metal_type] * this.gutsTotalWeight);
        },
        // Floor calcs
        floorMaterialWeight: function() {
            return this.metalWeight[this.data.metal_type][this.data.floor_ga];
        },
        floorTotalArea: function() {
            let area = 0;
            for (let i = 0; i < this.data.floor.length; i++) {
                area += (this.data.floor[i]['length'] * this.data.floor[i].height * this.data.floor[i].pcs) / 144;
            }
            return this.round3(area * 1.25);
        },
        floorTotalWeight: function() {
            return this.round3(this.floorMaterialWeight * this.floorTotalArea);
        },
        floorMaterialCost: function() {
            return this.round2(this.costs[this.data.metal_type] * this.floorTotalWeight);
        },
        // Insulation Calcs
        insulationArea: function() {
            return this.round3((this.gutsTotalArea + this.shellTotalArea) / (1 + this.scrap) * 1.15);
        },
        insulationFloorArea: function() {
            return this.round3(this.floorTotalArea * 1.25);
        },
        insulationFloorMaterialCost: function() {
            let insulationFloorCost = (this.data.insulation_floor) ? this.insulationCosts[this.data.insulation_type][this.data.insulation_size] : 0;
            return this.round2(this.insulationFloorArea * insulationFloorCost);
        },
        insulationMaterialCost: function() {
            let insulationMaterialCost = this.insulationArea * this.insulationCosts[this.data.insulation_type][this.data.insulation_size];
            return this.round2(insulationMaterialCost);
        },
        insulationTotalWeight: function() {
            let weight = (this.insulationCosts[this.data.insulation_type][this.data.insulation_size]  > 0) ? 0.125 * this.insulationArea : 0;
            weight += (this.data.insulation_floor && this.insulationCosts[this.data.insulation_type][this.data.insulation_size] > 0) ? 0.125 * this.insulationFloorArea : 0;
            return weight;
        },
        // Other Calcs
        angleIronMaterialCost: function() {
            let angleIronFT1 = this.data.angle_iron_pcs1 * 10;
            let angleIronFT2 = this.data.angle_iron_pcs2 * 10;
            let tubesteelFT = this.data.tubesteel_pcs * 10;
            return this.round2((this.costs[5] * angleIronFT1) + (this.costs[6] * angleIronFT2) + (this.costs[7] * tubesteelFT));
        },
        angleIronTotalWeight: function() {
            let angleIronFT1 = this.data.angle_iron_pcs1 * 10;
            let angleIronFT2 = this.data.angle_iron_pcs2 * 10;
            let tubesteelFT = this.data.tubesteel_pcs * 10;
            return angleIronFT1 * 1.92 + angleIronFT2 * 3.19 + tubesteelFT * 5.41;
        },
        gasketRolls: function() {
            let num = 0;
            if (this.isAdapter) {
                num = Math.ceil(((2 * this.data.oldx + 2 * this.data.oldy + 2 * this.data.newx + 2 * this.data.newy) / 12) / 50);
            }else {
                num = Math.ceil((((((this.data.newx * 2) + (this.data.newy * 2)) / 12) * 1.35) / 50));
            }
            return num;
        },
        gasketMaterialCost: function() {
            return this.round2(this.gasketRolls * this.costs[10] * 50);
        },
        ga24TotalArea: function() {
            let area = 0;
            for (let i = 0; i < this.data.ga.length; i++) {
                area += (this.data.ga[i]['length'] * this.data.ga[i].height * this.data.ga[i].pcs) / 144;
            }
            return this.round3(area * 1.25);
        },
        ga24MaterialCost: function() {
            return this.round2(this.costs[1] * this.ga24TotalArea * 1.139); // 1.139 is for weight
        },
        laborMins: function() {
            let mins = 0;
            if (this.isAdapter){
                mins = (this.data.labor_plasma + this.data.labor_grind +this.data.labor_form + this.data.labor_clinch + this.data.labor_shell + this.data.labor_gut + this.data.labor_insulate + this.data.labor_crate);
            }else{
                mins = (this.data.labor_shear + this.data.labor_form + this.data.labor_shell + this.data.labor_gut + this.data.labor_nailer + this.data.labor_crate);
            }
            return mins;
        },
        laborHours: function() {
            return this.round3(this.laborMins / 60);
        },
        laborCost: function() {
            return this.round2(this.laborHours * this.cache.curbCache.LABOR_RATE);
        },
        dwWallsMaterialCost: function() {
            let cost = 0;
            if (this.data.dw_walls){
                cost = (this.shellTotalArea * 2.139 * this.costs[1]);
            }
            return this.round2(cost);
        },
        dwFloorsMaterialCost: function() {
            let cost = 0;
            if (this.data.dw_floors) {
                cost = this.floorTotalArea * 2.139 * this.costs[1];
            }
            return this.round2(cost);
        },
        dwTotalWeight: function() {
            let weight = 0;
            if (this.data.dw_walls){
                weight += this.shellTotalArea;
            }
            if (this.data.dw_floors){
                weight += this.floorTotalArea;
            }
            return weight;
        },
        dwMaterialCost: function() {
            return this.dwWallsMaterialCost + this.dwFloorsMaterialCost + this.perfMaterialCost + this.dwAcousticalMaterialCost;
        },
        dwAcousticalArea: function() {
            return this.round2(this.floorTotalArea * 1.15);
        },
        dwInsulationWeight: function() {
            return this.round2(this.dwAcousticalArea * (2 / 12) * 3);
        },
        dwSheetRockWeight: function() {
            return this.round2(this.dwAcousticalArea * 1.76);
        },
        dwAcousticalMaterialCost: function() {
            let cost = 0;
            if (this.data.dw_accou) {
                cost = (this.costs[12] * this.dwAcousticalArea) + (this.costs[13] * this.dwAcousticalArea * 2);
            }
            return this.round2(cost);
        },
        perfMaterialCost: function() {
            let cost = 0;
            if (this.data.perf) {
                cost = ((this.shellTotalArea + this.gutsTotalArea + this.floorTotalArea) * this.costs[11]);
            }
            return this.round2(cost);
        },
        // Lumber calcs
        lumber2x4MaterialCost: function() {
            return this.round2(this.costs[9] * this.data.lumber_pcs);
        },
        lumberFT: function() {
            let cost = 0;
            if (!this.isAdapter) {
                cost = (2 * this.data.newx + 2 * this.data.newy) / 12 * 1.15;
            }
            return this.round2(cost);
        },
        lumber1x4MaterialCost: function() {
            let cost = 0;
            if (!this.isAdapter) {
                cost = (this.lumberFT * this.costs[8]);
            }
            return this.round2(cost);
        },
        lumberMaterialCost: function() {
            return this.round2(this.lumber2x4MaterialCost + this.lumber1x4MaterialCost);
        },
        // Totals calcs
        totalWeight: function() {
            let weight = this.gutsTotalWeight / 1.25 + this.floorTotalWeight / 1.25 + this.angleIronTotalWeight + this.insulationTotalWeight;
            if (this.isAdapter){
                weight += (this.shellTotalWeight / 1.50);
            }else{
                weight += (this.shellTotalWeight / 1.25) + this.dwTotalWeight;
            }
            return this.round2(weight);
        },
        materialsTotal: function() {
            return this.round2(
                this.shellMaterialCost
                + this.gutsMaterialCost
                + this.floorMaterialCost
                + this.insulationFloorMaterialCost
                + this.insulationMaterialCost
                + this.angleIronMaterialCost
                + this.lumberMaterialCost
                + this.gasketMaterialCost
                + this.ga24MaterialCost
                + this.dwMaterialCost
            );
        },
        cogsTotal: function() {
            return this.round2(this.laborCost + this.materialsTotal + this.data.freight_cost + this.data.extra_cost);
        },
        sellNum: function() {
            return this.round2(this.cogsTotal / (1 - this.data.gp / 100));
        },
        laborPercent: function() {
            return this.round2(this.laborCost / this.sellNum * 100);
        },
        materialsPercent: function() {
            return this.round2(this.materialsTotal / this.sellNum * 100);
        },
        freightPercent: function() {
            return this.round2(this.data.freight_cost / this.sellNum * 100);
        },
        extraPercent: function() {
            return this.round2(this.data.extra_cost / this.sellNum * 100);
        },
        cogsPercent: function() {
            return this.round2(this.cogsTotal / this.sellNum * 100);
        },
    },
}
</script>

<style scoped>
.result_f {
    color: #b50a37;
}
div.fixed {
    color: #015289;
    background-color: rgba(1, 82, 137, .1);
}
div.mid {
    background-color: rgba(0, 0, 0, .1);
}
div.result {
    color: #b50a37;
    background-color: rgba(181, 10, 55, .1);
}
.grid_cell {
    width: 33.33333333% !important;
    float: left;
}
.btn-primary{
    margin-top: 4px;
}
</style>